@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: 'Kalam', cursive;
  font-size: 20px;
  line-height: 1.3;
  text-align: justify;
  color: #ffffff;
}

body {
  background-color: #073B4C;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
